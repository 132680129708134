import { APP_TYPES, BROWSER_STORAGE_KEYS } from './constants';
import { getAuthGuidId, getOrderId, getScriptDataset } from './script';
import {
  getCurrentAccount,
  getBrowserStorageWithExpiry,
  getTrackingId,
  setBrowserStorageWithExpiry,
} from './util';

/**
 * Function used to set the current account object in browser storage
 * @param currentAccountData - currently selected account data object
 */
export const setCurrentAccountBrowserStorage = (currentAccountData) => {
  if (!currentAccountData) {
    console.warn(
      `Error setting current account! Expected currentAccount object, received: ${currentAccountData}`
    );
    return;
  }

  const trackingId = getTrackingId();

  if (trackingId === 'unAuthTrackingId') {
    return;
  }

  const currentAccount = {
    authGuid: currentAccountData.authGuid,
    orderId: currentAccountData.orderId,
  };

  const timeToLive = 1800000; // 30 minutes

  setBrowserStorageWithExpiry(
    BROWSER_STORAGE_KEYS.currentAccount,
    JSON.stringify(currentAccount),
    {
      isLocalStorage: false,
      timeToLive,
      trackingId,
    }
  );
  setBrowserStorageWithExpiry(
    BROWSER_STORAGE_KEYS.currentAccount,
    JSON.stringify(currentAccount),
    { timeToLive, trackingId }
  );
};

/**
 * Function used to synchronize backend data and local storage with the currently selected account based on application type.
 * @param currentAccountId - currently selected authGuid or orderId from backend call
 * @param userData - full user data object from backend call
 * @param switchAccountCallback - callback function from AccountManagerProvider to switch account when data does not match
 */
export const syncCurrentAccountByAppType = (
  currentAccountId,
  userData,
  switchAccountCallback
) => {
  const appType = getScriptDataset()?.applicationType;

  const { accounts } = userData ?? {};

  switch (appType) {
    case APP_TYPES.RESOURCE_URL: {
      // RESOURCE_URL - check script tag value, switch to that account if BE data does not match, set local storage
      const scriptValue = getAuthGuidId() || getOrderId();

      if (!scriptValue) {
        setCurrentAccountBrowserStorage(
          getCurrentAccount(accounts, currentAccountId)
        );
        break;
      }

      const currentAccount = getCurrentAccount(accounts, scriptValue);

      setCurrentAccountBrowserStorage(currentAccount);

      if (scriptValue !== currentAccountId) {
        switchAccountCallback(currentAccount);
      }
      break;
    }
    case APP_TYPES.SERVER: {
      // Legacy - always set browser storage with value from backend call
      setCurrentAccountBrowserStorage(
        getCurrentAccount(accounts, currentAccountId)
      );
      break;
    }
    case APP_TYPES.SPA: {
      // SPA - check browser storage for account and session id, otherwise, get from BE call
      const browserStorageAccount = JSON.parse(
        getBrowserStorageWithExpiry(
          BROWSER_STORAGE_KEYS.currentAccount,
          getTrackingId()
        )
      );

      if (!browserStorageAccount) {
        setCurrentAccountBrowserStorage(
          getCurrentAccount(accounts, currentAccountId)
        );
        break;
      }

      const currentAccount = getCurrentAccount(
        accounts,
        browserStorageAccount.authGuid
      );

      const accountId = currentAccount?.authGuid || currentAccount?.orderId;

      setCurrentAccountBrowserStorage(currentAccount);

      if (accountId !== currentAccountId) {
        switchAccountCallback(currentAccount);
      }
      break;
    }
  }
};
