import { matchFlags, matchDisplayFlags } from './displayRules';

/**
 * Function used to filter content that should not be rendered in the footer
 * @param data - content to filter
 * @returns {[]} - content that should only be rendered in footer
 */
export const filterHeaderOnlyLinks = (data) => {
  return (
    data
      ?.map((item) => {
        if (item.DisplayCondition?.isHeaderOnly) {
          return;
        }
        if (item.children?.length) {
          return {
            ...item,
            children: item.children
              .map((child) => {
                if (child.DisplayCondition?.isHeaderOnly) {
                  return;
                }
                return child;
              })
              .filter((x) => x),
          };
        }
        return item;
      })
      .filter((x) => x) ?? []
  );
};

/**
 * Function to filter out footer links according to value and DisplayCondition
 * @param {array[object]} footerData - array of footer links
 * @param {object} values - values to match against
 * @param {array[string]} displayFlags - array of display conditions
 * @param {object} featureFlags - object containing feature flags from context
 * @returns {array} return array of objects with appropriate footer links according
 * to value and DisplayCondition
 */
export const filterFooterLinks = (
  footerData,
  values = { isLoggedIn: false, isEnterprise: false },
  displayFlags = ['isLoggedIn', 'isEnterprise'],
  featureFlags
) => {
  return footerData.reduce((res, item) => {
    if (item.children?.length) {
      const newItem = {
        ...item,
        children: filterFooterLinks(
          item.children,
          values,
          featureFlags,
          displayFlags
        ),
      };
      res.push(newItem);
      return res;
    }
    if (!item.DisplayCondition) {
      res.push(item);
      return res;
    }

    matchFlags(item, featureFlags) &&
      matchDisplayFlags(item, displayFlags, values) &&
      res.push(item);

    return res;
  }, []);
};
