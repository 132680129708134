import defaultConfig from './default';
import qaConfig from './qa';
import pqaConfig from './pqa';
import intConfig from './int';
import stgConfig from './stg';
import prodConfig from './prod';
import perfConfig from './perf';

import { getScriptDataset } from 'utils/script';

const currentEnvironment = () => {
  const { environment } = getScriptDataset();

  switch (environment) {
    case 'prod':
    case 'prod-or':
    case 'prod-va': // TODO remove when Chub stg env is fixed
      return 'prod';
    case 'stg':
    case 'stg-or':
    case 'stg-va': // TODO remove when Chub stg env is fixed
    case 'stage': // TODO remove when Billing/TV stg env is fixed
      return 'stg';
    case 'local':
    case 'dev':
    case 'qa':
    case 'pqa':
    case 'int':
    case 'perf':
      return environment;
    default: {
      console.warn(
        `Invalid environment passed from script tag! Expected valid env, received ${environment}`
      );
      return process.env.NODE_ENV;
    }
  }
};

export const getConfig = () => {
  const env = currentEnvironment();
  switch (env) {
    case 'qa':
      return qaConfig;
    case 'pqa':
      return pqaConfig;
    case 'stg':
      return stgConfig;
    case 'prod':
      return prodConfig;
    case 'int':
      return intConfig;
    case 'perf':
      return perfConfig;
    default:
      return defaultConfig;
  }
};

export default getConfig();
