import { useEffect, useState } from 'react';

// Default options required by MutationObserver API
const DEFAULT_OPTIONS = {
  config: { attributes: true, childList: true, subtree: true },
};

/**
 * This custom hook abstracts the usage of the Mutation Observer from the client.
 * When changes occur to the DOM tree, a custom callback function will be triggered.
 * @param {Element} targetEl DOM element to be observed
 * @param {Function} callBack that will run when changes are detected in targetEl or its children
 * @param {Object} options
 * @param {Object} options.config check \[options\](https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe)
 **/
export const useMutationObserver = (
  targetEl,
  callBack,
  options = DEFAULT_OPTIONS
) => {
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    if (typeof callBack !== 'function') {
      console.warn(
        `Expected a valid callback function, instead received ${callBack}`
      );
      return;
    }
    setObserver(new MutationObserver(callBack));
  }, [callBack, options, setObserver]);

  useEffect(() => {
    if (!observer) return;
    if (!targetEl || !(targetEl instanceof Node)) {
      console.warn(
        `A valid DOM element must be provided in order to observe. Instead received ${targetEl}`
      );
      return;
    }
    const { config } = options;

    try {
      observer?.observe(targetEl, config);
    } catch (e) {
      console.error(e);
    }

    return () => {
      if (observer) {
        // Remove listener during unmount
        observer.disconnect();
      }
    };
  }, [observer, targetEl, options]);
};
