import PropTypes from 'prop-types';

export const BaseContentItem = PropTypes.shape({
  children: PropTypes.array,
  DisplayLink: PropTypes.string,
  DisplayText: PropTypes.string,
});

export const L1Data = PropTypes.shape({
  comcastLogoLink: PropTypes.object,
  level1_left: PropTypes.arrayOf(BaseContentItem),
  level1_right: PropTypes.shape({
    authenticated: PropTypes.array,
    comcastmobile: PropTypes.array,
    enterprise: PropTypes.array,
    unauthenticated: PropTypes.array,
  }),
  searchUrl: PropTypes.shape({
    nonShop: PropTypes.string,
    shop: PropTypes.string,
  }),
});

export const ProfileData = PropTypes.shape({
  userContext: PropTypes.shape({
    myProfile: PropTypes.shape({
      children: PropTypes.arrayOf(BaseContentItem),
    }),
  }),
});

export const FooterModifier = PropTypes.oneOf(['enterprise', 'smallBusiness']);
