import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { fidoV2 as fido, unauthenticatedLogger } from '@bsd/ui-utils';
import { getConfig } from './config';

// Utilities
import {
  getHeaderComponent,
  getFooterComponent,
  getIsObservabilityEnabled,
} from 'utils/script';
import { getTrackingIdHeader } from 'utils/util';

import './index.scss';

const headerComponent = getHeaderComponent();
const headerDomElement = document.getElementById(headerComponent?.elementId);

const footerComponent = getFooterComponent();
const footerDomElement = document.getElementById(footerComponent?.elementId);

const showNavElements = () => {
  if (!headerDomElement) {
    console.error(
      'Could not find header DOM element! Header component is required and must be passed via script tag!'
    );
    return;
  }
  headerDomElement.style.display = 'unset';

  // Since footer is optional, return if not provided
  if (!footerComponent) return;

  // If a footer component is provided but no valid dom element exists, display a warning and return
  if (footerComponent && !footerDomElement) {
    console.warn(
      'Could not find footer DOM element! ' +
        'If footer is not required, remove it from components in script tag, otherwise define a valid DOM element to inject footer.'
    );
    return;
  }
  footerDomElement.style.display = 'unset';

  const { ['tracking-id']: trackingId } = getTrackingIdHeader();
  const { fidoConfig, loggerConfig } = getConfig();

  fidoConfig.headers['tracking-id'] = trackingId;
  // Must have visitorSessionId to properly track logging
  loggerConfig.visitorSessionId = trackingId;

  const isObservabilityEnabled = getIsObservabilityEnabled();

  if (!isObservabilityEnabled) {
    unauthenticatedLogger.config(loggerConfig);
  }

  fido.init({
    ...fidoConfig,
    enableLogging: !isObservabilityEnabled,
    loggingProvider: !isObservabilityEnabled ? unauthenticatedLogger : null,
  });
};

const renderNav = (isMegaMenu) => {
  window.localStorage.removeItem('isFtueRunning');

  ReactDOM.render(
    <React.StrictMode>
      <App
        footerComponent={footerComponent}
        headerComponent={headerComponent}
        isMegaMenu={isMegaMenu}
      />
    </React.StrictMode>,
    headerDomElement,
    showNavElements
  );
};

export const loadNav = () => {
  const isMegaMenu = true;
  renderNav(isMegaMenu);
};

loadNav();
