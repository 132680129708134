import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import GlobalFooter from 'components/templates/Footer/GlobalFooter';
import { DataContext } from 'context/data/DataContextProvider';
import { FlowFooter } from 'bsd-global-nav-design-ui';
import { FooterModifier, L1Data } from 'utils/shapes';
import { getFooterComponent } from 'utils/script';
import { filterFooterLinks } from 'utils/footerUtils';
import { containerTracking } from '@bsd/ui-utils/tracking';

export default function FooterWrapper({
  footerComponent,
  footerL1Data,
  footerL2Data,
  footerModifier,
  hubData,
}) {
  const { featureFlags, footer, scriptData, isAuthenticated } =
    useContext(DataContext);

  // TEMPORARY FUNCTIONALITY - this is to allow QA to test footer variants via script tag
  const [footerType, setFooterType] = useState(footerComponent?.type);

  useEffect(() => {
    if (footerType !== getFooterComponent()?.type) {
      setFooterType(getFooterComponent()?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptData]);
  // END OF TEMPORARY FUNCTIONALITY

  if (!(footerComponent && footerComponent.type)) return null;

  const footerDomElement = document.getElementById(footerComponent?.elementId);
  if (!footerDomElement) return null;

  const {
    flow_Footer: { flowLinks: footerData, flowIcons: icons },
  } = footer ?? [];

  const flowFooterLinks = filterFooterLinks(
    footerData,
    {
      isLoggedIn: isAuthenticated,
      isEnterprise: footerModifier === 'enterprise',
    },
    ['isLoggedIn', 'isEnterprise'],
    featureFlags
  );

  return ReactDOM.createPortal(
    <footer
      data-tracking={containerTracking('footer nav')}
      id="bsd-nav-footer-section"
    >
      {footerType === 'GLOBAL_FOOTER' ? (
        <GlobalFooter
          flowFooterData={flowFooterLinks}
          footerL1Data={footerL1Data}
          footerL2Data={footerL2Data}
          footerModifier={footerModifier}
          hubData={hubData}
        />
      ) : (
        <FlowFooter data={flowFooterLinks} footerIcons={icons} />
      )}
    </footer>,
    footerDomElement
  );
}

FooterWrapper.propTypes = {
  footerComponent: PropTypes.object,
  footerL1Data: L1Data,
  footerL2Data: PropTypes.array,
  footerModifier: FooterModifier,
  hubData: PropTypes.object,
  l2Data: PropTypes.array,
};
