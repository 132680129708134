const cartUrl = 'https://qa.cart.ecom.np.digital.business.comcast.com/cart.js';
const cimaLoginUrl = 'https://business.qa.comcast.com/account/';
const contentUrl =
  'https://global-nav-content-qa.np.digital.business.comcast.com/content.json';
const megaMenuContentUrl =
  'https://mega-menu-content-qa.np.digital.business.comcast.com/content.json';
const loggingBaseUrl =
  'https://dev.core-gateway.np.api-business.comcast.com/logging-api';
const navigationSaUrl =
  'https://qa.web1.self-service.np.digital.business.comcast.com/account/ss-service-navigation/';

const navSaRetryLimit = 4;
const defaultRetryMillis = 10000;

export default {
  cartConfig: {
    cartUrl,
  },
  cimaConfig: {
    cimaLoginUrl,
  },
  contentConfig: {
    contentUrl,
    megaMenuContentUrl,
  },
  isProductionEnvironment: false,
  navSaConfig: {
    defaultRetryMillis,
    navigationSaUrl,
    navSaRetryLimit,
  },
  fidoConfig: {
    headers: {
      'tracking-id': '',
      'x-api-key': 'tgnvhW9jET8J9pQT3iET36M3DvVYRgCw5EbBwSjo',
    },
    dataApiConfig: {
      logging: {
        baseUrl: loggingBaseUrl,
        sensitiveFields: null,
      },
      bcpSwitch: {
        baseUrl: 'https://business.qa.comcast.com/',
      },
    },
  },
  loggerConfig: {
    appSettings: {
      applicationId: 'ss-global-nav',
      apiKey: '5mtZoXDeTX9CP1IW9Vqbd7Fk2cyej6Lp1NPAaBTM',
      appVersion: 3,
      loggingEndpoint: {
        url: '/event',
        baseURL: loggingBaseUrl,
      },
    },
    environment: 'qa',
  },
  featureFlags: {
    listenToCBVA: true,
    isApplicationTypeEnabled: true,
    isBcpSwitchAccountCallDisabled: true,
  },
};
