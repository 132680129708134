import { Component } from 'react';
import PropTypes from 'prop-types';
import { trackEvent, events } from 'utils/tracking';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    trackEvent(events.GLOBAL_NAV_RENDER_ERROR(errorInfo.componentStack));
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return null;
    }

    return children;
  }
}

export default ErrorBoundary;
