export const DESKTOP_QUERY = '(min-width: 1024px)';

export const APP_NAMES = {
  Activecore: 'Activecore',
  BSEE: 'BusinessServicesEcommerceExperience',
  CustomerHub: 'CustomerHub',
  HelpAndSupport: 'HelpAndSupport',
  MobileLearn: 'ComcastBusinessMobileLearn',
  MobileBuy: 'ComcastBusinessMobileBuy',
  MyAccount: 'MyAccount',
};

export const APP_PATHS = {
  accountOverview: '/account/overview/',
};

export const NAV_ROUTE_CHANGE_EVENT = 'navigation-active-path-change';

export const APP_TYPES = {
  SPA: 'SPA',
  RESOURCE_URL: 'RESOURCE_URL',
  SERVER: 'SERVER',
};

export const AUTH_TYPES = ['CIMA', 'OKTA', 'COM_AUTH_CORE'];

export const CLOSED_ACCOUNT_ROLES = {
  BusinessOwner: true,
  BillPayManager: true,
  AdministratorBillPayCombo: true,
};

export const EVENT_METHODS = {
  sendError: 'send-Error',
};

export const EVENT_NAME = {
  globalNavTransactionError: 'global nav transaction error',
};

export const EVENT_ACTIONS = {
  errorRenderingGlobalNav: 'error rendering Global Nav',
};

export const EVENT_TYPES = {
  accountSwitchWithNavigate: 'Nav-Account-Switch-With-Navigate',
};

export const API_CALL_TYPE = {
  navContent: 'nav content',
  megaMenuContent: 'mega menu content',
  userData: 'user data',
  switchAccount: 'switch account',
  navEnablement: 'nav enablement',
};
export const API_ERROR_MESSAGE = (callName, status) => {
  return `Unable to fetch ${callName}, Unsuccessful Navigation SA call with status ${status}`;
};

export const BROWSER_STORAGE_KEYS = {
  currentAccount: 'currentAccount',
};

export const WINDOW_EVENTS = {
  navFlowHeaderClick: 'bsd-nav-flow-header-click',
};

export const UN_AUTH_TRACKING_ID = 'unAuthTrackingId';

export const MEGA_MENU_STORAGE_KEY = 'megaNavEnabled';
