import { getNavSaConfig } from './util';
import { ApiSA as NavServiceSa } from '@bsd/service-agent-selfservice-service-navigation-api';

const { navigationSaUrl } = getNavSaConfig();

let navSA = null;

const getNavSaInstance = () => {
  if (navSA === null) {
    navSA = new NavServiceSa(navigationSaUrl);
  }

  return navSA;
};

export default getNavSaInstance;
