import { addDynamicAuthGuid } from './util';

const RULE_FLAGS = [
  'isPreAccount',
  'isPreInstall',
  'isActive',
  'isOrion',
  'isSingleView',
  'isBvMigrationEligible',
  'isClosed',
  'isCsg',
  'hasMixedAccounts',
];
const RULE_FLAGS_INACTIVE_NON_ORION_ACCOUNT = [
  ...RULE_FLAGS,
  'isSuspended',
  'isDisconnected',
];
const RULE_FLAGS_ORION_ACCOUNT = [...RULE_FLAGS, 'isDisconnected'];

export const matchGenericRules = (navItem, accountData, flags = RULE_FLAGS) => {
  const navItemChecks = navItem.DisplayCondition;

  if (!navItemChecks) {
    return true;
  }

  const failsRuleCheck = flags.some((flag) => {
    return (
      navItemChecks[flag] !== undefined &&
      navItemChecks[flag] !== accountData[flag]
    );
  });

  return !failsRuleCheck;
};

export const matchRole = (navItem, accountData) => {
  const { validUserRoles } = navItem.DisplayCondition || {};

  if (!validUserRoles) {
    return true;
  }

  return !!accountData.roles?.find((role) => validUserRoles[role]);
};

export const matchServices = (navItem, accountData) => {
  const { validAccountServices } = navItem.DisplayCondition || {};

  if (!validAccountServices) {
    return true;
  }

  return !!accountData.services?.find(
    (service) => validAccountServices[service]
  );
};

export const matchUserServices = (navItem, accountData) => {
  const { validUserServices } = navItem.DisplayCondition || {};

  if (!validUserServices) {
    return true;
  }

  return !!accountData.userServices?.find(
    (service) => validUserServices[service]
  );
};

export const matchFlags = (navItem, featureFlags) => {
  const { validFeatureFlags } = navItem.DisplayCondition || {};

  if (!validFeatureFlags) {
    return true;
  }

  return Object.keys(validFeatureFlags).every(
    (flag) => validFeatureFlags[flag] === (featureFlags[flag] || false)
  );
};

export const matchDisplayFlags = (navItem, flags, value) => {
  const navItemChecks = navItem.DisplayCondition;

  if (!navItemChecks || !flags.length) {
    return true;
  }

  return flags.some((flag) => navItemChecks[flag] === value[flag]);
};

export const matchRules = (navItem, accountData, featureFlags) => {
  let flagRules = RULE_FLAGS;

  if (
    accountData?.isOrion &&
    featureFlags?.IsInActiveProcessingForOrionEnabled
  ) {
    flagRules = RULE_FLAGS_ORION_ACCOUNT;
  } else if (
    !accountData?.isOrion &&
    featureFlags?.IsInActiveAccountExperienceEnabled
  ) {
    flagRules = RULE_FLAGS_INACTIVE_NON_ORION_ACCOUNT;
  }

  return (
    matchGenericRules(navItem, accountData, flagRules) &&
    matchRole(navItem, accountData) &&
    matchServices(navItem, accountData) &&
    matchUserServices(navItem, accountData) &&
    matchFlags(navItem, featureFlags)
  );
};

export const applyRules = (hubItems, accountData, featureFlags) => {
  const filterItems = (items) => {
    return items.reduce((res, item) => {
      if (matchRules(item, accountData, featureFlags)) {
        const newItem = { ...item };

        if (newItem.children) {
          newItem.children = filterItems(newItem.children);
        }

        // if the item requires children, but none were returned from filter, do not show parent
        if (
          newItem.DisplayCondition?.requiresChildren &&
          newItem.children?.length === 0
        ) {
          return res;
        }
        if (newItem?.DisplayCondition?.appendAuthGuid) {
          addDynamicAuthGuid(newItem, accountData);
        }

        res.push(newItem);
      }
      return res;
    }, []);
  };

  return filterItems(hubItems);
};
