import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Sprite } from 'bsd-global-nav-design-ui';
import { containerTracking } from '@bsd/ui-utils/tracking';

import config from 'config';
import { APP_NAMES } from 'utils/constants';
import { getAppName } from 'utils/script';

import { DataContextProvider } from 'context/data/DataContextProvider';
import { AccountManagerProvider } from 'context/data/AccountManagerProvider';
import GlobalWrapper from 'components/templates/GlobalWrapper/GlobalWrapper';
import ErrorBoundary from 'components/Error/ErrorBoundary';

export default function App({ headerComponent, footerComponent, isMegaMenu }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { cartConfig } = config;
    const appName = getAppName();

    if (
      headerComponent.type === 'GLOBAL_HEADER' &&
      (appName === APP_NAMES.BSEE ||
        appName === APP_NAMES.MobileBuy ||
        appName === APP_NAMES.MobileLearn)
    ) {
      const cartScript = document.createElement('script');
      cartScript.src = cartConfig.cartUrl;
      cartScript.async = true;
      document.body.appendChild(cartScript);
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading]);

  return (
    <div className="bsd-nav" data-tracking={containerTracking('global nav')}>
      <Sprite />
      {loading ? (
        'Loading...'
      ) : (
        <AccountManagerProvider>
          <DataContextProvider isMegaMenu={isMegaMenu}>
            <ErrorBoundary>
              <GlobalWrapper
                footerComponent={footerComponent}
                headerComponent={headerComponent}
              />
            </ErrorBoundary>
          </DataContextProvider>
        </AccountManagerProvider>
      )}
    </div>
  );
}

App.propTypes = {
  footerComponent: PropTypes.object,
  headerComponent: PropTypes.object,
  isMegaMenu: PropTypes.bool,
};
