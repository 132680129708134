import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GlobalHeader from './GlobalHeader';
import { FlowHeader } from 'bsd-global-nav-design-ui';
import { DataContext } from 'context/data/DataContextProvider';
import { getLogoUrl } from 'utils/util';
import { getAppName, getHeaderComponent } from 'utils/script';
import { WINDOW_EVENTS } from 'utils/constants';
import { BaseContentItem, L1Data, ProfileData } from 'utils/shapes';

export default function HeaderWrapper({
  headerComponent,
  hubData,
  l0Data,
  l1Data,
  l2Data,
  phoneData,
  profileData,
}) {
  const { header, isAuthenticated, scriptData } = useContext(DataContext);

  // TEMPORARY FUNCTIONALITY - this is to allow QA to test header variants via script tag
  const [headerType, setHeaderType] = useState(headerComponent?.type);

  useEffect(() => {
    if (headerType !== getHeaderComponent()?.type) {
      setHeaderType(getHeaderComponent()?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptData]);
  // END OF TEMPORARY FUNCTIONALITY

  if (!(headerComponent && headerComponent.type)) return null;

  const { comcastLogoLink: logoLinks } = header.level_1_Navigation;

  const logoUrl =
    scriptData?.homepageOverride ??
    getLogoUrl(getAppName(), isAuthenticated, logoLinks);

  const flowHeaderData =
    headerType === 'FLOW_HEADER' &&
    scriptData?.flowHeader &&
    JSON.parse(scriptData?.flowHeader);

  const hasCustomAction = flowHeaderData?.customAction === 'true';
  const hideCloseButton = flowHeaderData?.hideCloseButton === 'true';

  const customHandler = () => {
    window.dispatchEvent(new CustomEvent(WINDOW_EVENTS.navFlowHeaderClick));
  };

  return (
    <>
      {headerType === 'GLOBAL_HEADER' ? (
        <GlobalHeader
          hubData={hubData}
          l0Data={l0Data}
          l1Data={l1Data}
          l2Data={l2Data}
          phoneData={phoneData}
          profileData={profileData}
        />
      ) : (
        <FlowHeader
          buttonIcon={flowHeaderData?.buttonIcon}
          buttonText={flowHeaderData?.buttonText}
          customAction={hasCustomAction ? customHandler : undefined}
          exitUrl={flowHeaderData?.closeButtonUrl}
          hideCloseButton={hideCloseButton}
          logoLink={logoUrl}
          phoneNumber={flowHeaderData?.phoneNumber}
        />
      )}
    </>
  );
}

HeaderWrapper.propTypes = {
  headerComponent: PropTypes.object,
  hubData: PropTypes.object,
  l0Data: PropTypes.arrayOf(BaseContentItem),
  l1Data: L1Data,
  l2Data: PropTypes.arrayOf(BaseContentItem),
  phoneData: PropTypes.shape({
    phoneNumber: PropTypes.string,
  }),
  profileData: ProfileData,
};
