import { ignoreL2 } from './script';

export const isSelected = (navItem) => {
  return navItem.DisplayCondition?.isSelected;
};

export const containsL2 = (l1MenuItem) => {
  return l1MenuItem.DisplayCondition?.containsL2;
};

export const containsMegaMenu = (l1MenuItem) => {
  return l1MenuItem.DisplayCondition?.containsMegamenu;
};

export const containsL2OrMegaMenu = (l1LeftItem, isMegaMenu) => {
  return isMegaMenu ? containsMegaMenu(l1LeftItem) : containsL2(l1LeftItem);
};

export const getRelevantL1ForL0 = (l1Items, currentL0) => {
  return l1Items.filter((item) => {
    const { validL0DisplayId } = item.DisplayCondition || {};

    return !!validL0DisplayId?.includes(currentL0?.DisplayId);
  });
};

/**
 * Sets L0 nav items selected states based on current location
 * @param currentPath - relative pathname used to match with content
 * @param l0NavData - L0 nav items to set selected states for, will be MUTATED by this function
 * @param isMegaMenu - flag used to determine which text to search for in L0 data
 */
export const setL0SelectedNavItems = (currentPath, l0NavData, isMegaMenu) => {
  const isCurrentPathEnterprise = currentPath?.startsWith('/enterprise');
  const navItemText = isMegaMenu
    ? 'comcast business'
    : isCurrentPathEnterprise
    ? 'enterprise'
    : 'small business';

  l0NavData.find(
    (item) => item.DisplayText?.toLowerCase() === navItemText
  ).DisplayCondition.isSelected = true;
};

export const normalizeUrl = (url) => {
  if (!url || typeof url !== 'string') {
    return null;
  }
  return url.endsWith('/') ? url.toLowerCase() : url.concat('/').toLowerCase();
};

const parseUrl = ($url) => {
  let url = $url.split('//');
  if (url[0] === 'http:' || url[0] === 'https:') {
    const protocol = url[0] + '//';
    const host = url[1].split('/')[0];
    url = protocol + host;
    let path = normalizeUrl($url.split(url)[1]);

    return {
      protocol,
      host,
      path,
    };
  }
};

/**
 * Recursive function which sets selected states in nav items based on current window location
 * @param navData - list of link objects retrieved from content, will be MUTATED by this function
 * @param currentPath - relative path to match
 * @param {boolean} [isMegaMenu] - mega menu enabled flag
 */
export const setSelectedNavItems = (currentPath, navData, isMegaMenu) => {
  if (!navData) {
    console.error(
      `A valid list of nav items must be passed in. Instead received: ${navData}`
    );
    return;
  }

  const parentPath = [];
  let foundSelection = false;

  const determineSelectedStates = (menuData, pathToMatch) => {
    for (const linkObj of menuData) {
      const parsedUrl =
        parseUrl(linkObj?.DisplayLink)?.path ??
        normalizeUrl(linkObj.DisplayLink);
      const ignoreSelectedStates = Boolean(
        linkObj?.DisplayCondition?.ignoreSelectedStates
      );
      const hasChildren = Boolean(linkObj.children && linkObj.children.length);
      const isMatch =
        (!ignoreSelectedStates && pathToMatch === parsedUrl) ||
        !!linkObj.AlternateLinks?.find(
          (alternate) => normalizeUrl(alternate) === pathToMatch
        );

      linkObj.DisplayCondition.isSelected = isMatch;

      // Continue to recurse when parent and child links match
      if (isMatch && !hasChildren) {
        foundSelection = true;

        if (linkObj.DisplayCondition.isDuplicate) {
          parentPath.forEach(
            (parent) => (parent.DisplayCondition.isSelected = true)
          );
          continue;
        }
        return true;
      }

      if (hasChildren) {
        parentPath.push(linkObj);
        const foundMatch = determineSelectedStates(
          linkObj.children,
          pathToMatch
        );

        if (foundMatch) {
          parentPath.forEach(
            (parent) => (parent.DisplayCondition.isSelected = true)
          );
          foundSelection = true;
          return;
        } else {
          parentPath.pop();
        }
      }
    }
  };

  determineSelectedStates(navData, normalizeUrl(currentPath));

  if (foundSelection || ignoreL2()) return;

  // Manually select support item for any page that includes the base path, but is not in content
  const supportItem = navData.find((item) => item.DisplayText === 'Support');
  if (supportItem && currentPath.includes('/support/')) {
    supportItem.DisplayCondition.isSelected = true;
    return;
  }

  // Manually select shop in order to display its L2 data when no selection found
  const shopItem = navData.find((item) => item.DisplayText === 'Shop');
  if (shopItem && !isMegaMenu) {
    shopItem.DisplayCondition.isSelected = true;
  }
};
