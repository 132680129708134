import React, { Fragment } from 'react';
import { ACTIONS, LIFECYCLE, STATUS } from 'react-joyride';
import classNames from 'classnames';
import {
  containerTracking,
  eventTrackingDetails,
} from '@bsd/ui-utils/tracking';

import { Button, Icon, Link, Text } from 'bsd-global-nav-design-ui';
import { CLOSE_BUTTON_TEST_ID } from 'components/FirstTimeUserExperience/constants';

import { ApiSA as NavServiceSa } from '@bsd/service-agent-selfservice-service-navigation-api';
import { getNavSaConfig, getTrackingIdHeader, getTracerName } from 'utils/util';
import {
  getComAuthCore,
  getScriptDataset,
  getIsObservabilityEnabled,
} from 'utils/script';
import { SemanticAttributes, spanRequest } from '@bsd/observability';

const baseClass = 'bsd-ftue';
const stepProps = {
  disableBeacon: true,
  hideCloseButton: true,
  isFixed: true,
};
const containerTrackingLabel = 'ftue';

const amendClassList = (element, className, action) => {
  if (element && className && action === ACTIONS.UPDATE) {
    element.classList.add(className);
  } else {
    if (element && className) {
      element.classList.remove(className);
    }
  }
};

export const formatMessage = (id, innerHtml) => {
  if (!id || !innerHtml) {
    return null;
  }

  return <span dangerouslySetInnerHTML={{ __html: innerHtml }} id={id}></span>;
};

/**
 * Makes a call to Navigation SA to disable the ftue.
 * Called from handleFtueCallback when
 * an authToken is present and the ftue is started
 *
 * @param {string} authToken authorization token
 * @param {string} processingMode ftue dismissal type either Hub or Nav
 *
 * @returns true upon success;
 * returns false and logs an error upon failure.
 */
export const getDisableFtue = async (authToken, processingMode = 'Nav') => {
  const { navigationSaUrl } = getNavSaConfig();
  const hasComAuthCore = Boolean(getComAuthCore());
  const isObservabilityEnabled = getIsObservabilityEnabled();
  const { ['tracking-id']: trackingId } = getTrackingIdHeader();

  try {
    const navSA = new NavServiceSa(navigationSaUrl);
    const disableRequestOptions = {
      headers: {
        authorization: authToken,
        ...(hasComAuthCore && { 'com-auth-core': getComAuthCore() }),
        ...getTrackingIdHeader(),
      },
      withCredentials: hasComAuthCore ? false : true,
    };

    const ftueDisableData = await (isObservabilityEnabled
      ? spanRequest({
          method: 'post',
          requestFn: () =>
            navSA.disable(processingMode, null, disableRequestOptions),
          tracerName: getTracerName('ftueDisableRequest'),
          attributes: {
            [SemanticAttributes.CODE_FUNCTION]: 'getDisableFtue',
            [SemanticAttributes.CODE_FILEPATH]: 'src/utils/ftueUtils.js',
            ref_url: document.referrer,
            'app.tracking_id': trackingId,
          },
          payload: JSON.stringify({ processingMode }),
          url: `${navigationSaUrl}disable`,
        })
      : navSA.disable(processingMode, null, disableRequestOptions));

    if (ftueDisableData?.data && ftueDisableData?.httpStatus === 200) {
      if (processingMode === 'Nav') {
        window.__GlobalNav = {
          ...window.__GlobalNav,
          firstTimeUserExperienceEnabled: false,
        };
      } else {
        window.__GlobalNav = {
          ...window.__GlobalNav,
          hubFirstTimeUserExperienceEnabled: false,
        };
      }
      return true;
    } else {
      console.error(
        `Unable to disable first time user experience. Unsuccessful Navigation SA call with status ${ftueDisableData.httpStatus}`
      );
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Generates a progress indicator component
 * wherein each step is represented by a dot that is styled to indicate
 * whether or not the step is completed in the ftue flow.
 * Progress indicator dots are updated while navigating through ftue
 *
 * @param {number} index current step index
 * @param {boolean} isDesktop result of media query hook; if true,
 * browser width is greater than desktop breakpoint
 * @param {number} numSteps number of steps
 *
 * @returns a list of indicators, displayed as dots,
 * to show where the user is in the ftue flow
 */
export const getProgressIndicator = (index, isDesktop, numSteps) => {
  const currentStep = index + 1;
  const indicators = [];
  const indicatorClass = `${baseClass}-indicator`;
  const progressIndicatorClass = `${baseClass}-progress-indicator`;

  for (let i = 0; i < numSteps; i += 1) {
    const className =
      i <= index
        ? `${indicatorClass} ${indicatorClass}--complete`
        : indicatorClass;
    indicators.push(<li className={className} key={`step-${i + 1}`}></li>);
  }
  return (
    <ul
      aria-label="First Time User Experience Progress"
      aria-valuemax={numSteps}
      aria-valuemin="1"
      aria-valuenow={currentStep}
      aria-valuetext={`Step ${currentStep}`}
      className={classNames(
        progressIndicatorClass,
        !isDesktop && `${progressIndicatorClass}--mobile`
      )}
      role="progressbar"
    >
      {indicators}
    </ul>
  );
};

/**
 * The callback function for React Joyride ftue.
 * Handles showing/hiding elements based upon the actionSelector clicks,
 * as well as handling behavior when the ftue is closed.
 *
 * @param {object} activeSelector string for the step.actionSelector that should be
 * clicked upon ending the tour
 * @param {object} helperFuncs object wherein each key has a function value that
 * can be used to control the ftue navigation (close, next, prev, reset)
 * @param {boolean} isDesktop result of media query hook; if true,
 * browser width is greater than desktop breakpoint
 * @param {function} setActiveSelector function that sets the state in parent component
 * to determine which selector should be clicked upon ending the tour
 * @param {function} setRunTour function that sets the state in parent component
 * to determine whether or not the React Joyride tour is running for ftue
 * @param {object} tour object provided by React Joyride with current tour data
 * that provides ftue status and updates as ftue changes
 * @param profileData
 * @param {string} processingMode - ftue dismissal type either Hub or Nav
 */
export const handleFtueCallback = (
  activeSelector,
  helperFuncs,
  isDesktop,
  setActiveSelector,
  setRunTour,
  tour,
  profileData,
  processingMode
) => {
  const { action, status, lifecycle, step } = tour;

  const addAndRemoveStyleClasses = (step, key) => {
    step?.[key]?.forEach((stepStyleSelector) => {
      const ftueStylingClassSelectors = document.querySelectorAll(
        stepStyleSelector?.selector
      );
      ftueStylingClassSelectors.forEach((ftueStylingClassSelector) =>
        amendClassList(
          ftueStylingClassSelector,
          stepStyleSelector.className,
          action
        )
      );
    });
  };

  if (action === ACTIONS.START) {
    window.scrollTo(0, 0);

    window.localStorage.setItem('isFtueRunning', true);

    // Add class to nav to apply ftue side menu styles
    document.querySelector('.bsd-nav').classList.add('bsd-nav--ftue-open');

    const { authToken } = getScriptDataset();

    if (authToken) {
      getDisableFtue(authToken, processingMode);
    }
  }

  // close and reset tour and click active selectors upon completion or close of tour
  if (
    status === STATUS.FINISHED ||
    status === STATUS.SKIPPED ||
    action === ACTIONS.CLOSE ||
    action === ACTIONS.STOP
  ) {
    helperFuncs.close();
    helperFuncs.reset();
    setRunTour(false);
    window.localStorage.removeItem('isFtueRunning');

    // Closing/finishing the tour does a stop with step 0
    // stopChecks has the selectors to click (close) if needed
    if (step?.stopChecks && step.stopChecks[activeSelector]) {
      const stopChecksSelectorExists = document.querySelector(
        step.stopChecks[activeSelector]
      );
      if (stopChecksSelectorExists) {
        document.querySelector(step.stopChecks[activeSelector]).click();
        document
          .querySelector(step.stopChecks[activeSelector])
          .removeAttribute('data-tracking');
      }
    }

    addAndRemoveStyleClasses(step, 'removeStyleClasses');

    amendClassList(tooltip, step?.tooltipClass, action);
    // Remove class from nav to remove ftue side menu styles
    document.querySelector('.bsd-nav').classList.remove('bsd-nav--ftue-open');
  }

  // add and remove spotlight and tooltip classes for each step
  const spotlight = document.querySelector('.react-joyride__spotlight');
  amendClassList(spotlight, step?.spotlightClass, action);

  const tooltip = document.querySelector('.__floater.__floater__open');
  amendClassList(tooltip, step?.tooltipClass, action);

  const profileDropdownSpotlight = document.querySelector(
    '.react-joyride__spotlight.profile-dropdown-spotlight'
  );

  if (profileDropdownSpotlight) {
    const modifier = profileData?.children?.length || 0;
    const calcHeight = modifier * 40 + 87; // 87 due to 70 for L1 and 17 extra padding, if needs to move up change to 114 and remove top from profile-dropdown-spotlight in scss
    profileDropdownSpotlight.style.top = '0';
    profileDropdownSpotlight.style.height = `${calcHeight}px`;
  }

  // need time to allow for Hub data calls to be made
  // since those calls will render the elements
  // to which style classes are applied.
  // without the timeout, elements may not exist
  // at the time they are queried in the function below.
  setTimeout(() => {
    addAndRemoveStyleClasses(step, 'styleClasses');
  }, 1000);

  // click selectors to open and close menus
  // and set active selectors while navigating through tour
  if (step?.actionSelector && isDesktop) {
    const trackingDetails = eventTrackingDetails(
      'ftue',
      step.stepTitle,
      'click',
      true
    );

    const selector = step.actionSelector;
    const selectorExists = !!document.querySelector(selector);

    if (action === ACTIONS.UPDATE && selectorExists) {
      document
        .querySelector(selector)
        .setAttribute('data-tracking', trackingDetails);
      document.querySelector(selector).click();
      setActiveSelector(selector);
    }
    if (
      (action === ACTIONS.PREV || action === ACTIONS.NEXT) &&
      lifecycle === LIFECYCLE.COMPLETE &&
      selectorExists
    ) {
      document.querySelector(selector).click();
      document.querySelector(selector).removeAttribute('data-tracking');
      setActiveSelector('');
    }
  }
};

/**
 * Renders a Button from the Nav Design UI
 *
 * @param {string} modifier button modifier provided by content
 * @param {string} text button text provided by content
 * @param {string} type button type provided by content which is used to determine
 * which helper function to call onClick
 * @param {object} helperFuncs object wherein each key has a function value that
 * can be used to control the ftue navigation (close, next, prev, reset)
 * @param {string} stepTitle step title provided by content to be used as container value for analytics tracking
 *
 * @returns a Button component
 */
export const renderButton = (modifier, text, type, helperFuncs, stepTitle) => {
  const handleOnClick = () => {
    if (type === 'back') {
      return helperFuncs.prev();
    } else if (type === 'skip') {
      return helperFuncs.close();
    } else {
      return helperFuncs.next();
    }
  };

  return (
    <Button
      data-tracking={eventTrackingDetails(stepTitle, text, 'click', true)}
      modifier={modifier === 'anchor' ? 'ftue-back' : 'ftue-next'}
      onClick={handleOnClick}
      type="button"
    >
      {text}
    </Button>
  );
};

/**
 * Renders a set of actions used to navigate through or from the ftue
 *
 * @param {array} actions array of action objects,
 * wherein each action has a `modifier`, `type`, and `text` property,
 * and may have a `link` property
 * @param {object} helperFuncs object wherein each key has a function value that
 * can be used to control the ftue navigation (close, next, prev, reset)
 * * @param {boolean} isDesktop result of media query hook; if true,
 * browser width is greater than desktop breakpoint
 *
 * @param {string} stepTitle step title provided by content to be used as container value for analytics tracking
 * @returns a set of actions; action order is reversed on mobile
 */
export const renderActions = (actions, helperFuncs, isDesktop, stepTitle) => {
  const mappedActions = actions.map((action) =>
    action?.type === 'custom' ? (
      <Link
        data-tracking={eventTrackingDetails(
          stepTitle,
          action.text,
          'click',
          true
        )}
        key={`${action.title} ${action.text} action`}
        modifier={action.modifier}
        to={action.link}
      >
        {action.text}
      </Link>
    ) : (
      <Fragment key={`${action.title} ${action.text} action`}>
        {renderButton(
          action.modifier,
          action.text,
          action.type,
          helperFuncs,
          stepTitle
        )}
      </Fragment>
    )
  );

  if (!isDesktop) {
    return mappedActions.reverse();
  }

  return mappedActions;
};

/**
 * Renders a close button
 *
 * @param {object} helperFuncs object wherein each key has a function value that
 * can be used to control the ftue navigation (close, next, prev, reset)
 * @param {function} setRunTour function that sets the state in parent component
 * to determine whether or not the React Joyride tour is running for ftue
 * @param {string} stepTitle step title provided by content to be used as container value for analytics tracking
 *
 * @returns a close button component
 */
export const renderCloseButton = (helperFuncs, setRunTour, stepTitle) => {
  const text = 'Close';
  return (
    <Button
      ariaLabel={text}
      className={`${baseClass}-btn--close`}
      data-testid={CLOSE_BUTTON_TEST_ID}
      data-tracking={eventTrackingDetails(stepTitle, text, 'click', true)}
      onClick={() => {
        helperFuncs.close();
        helperFuncs.reset();
        setRunTour(false);
      }}
      type="button"
    >
      <Icon name="close-x" size="m" />
    </Button>
  );
};

/**
 * Renders jsx for the ftue body based upon step content
 *
 * @param {object} helperFuncs object wherein each key has a function value that
 * can be used to control the ftue navigation (close, next, prev, reset)
 * @param {number} index current step index
 * @param {boolean} isDesktop result of media query hook; if true,
 * browser width is greater than desktop breakpoint
 * @param {number} numSteps number of steps
 * @param {function} setRunTour function that sets the state in parent component
 * to determine whether or not the React Joyride tour is running for ftue
 * @param {object} step object provided by content that contains step data
 *
 * @returns jsx to be displayed in ftue for the step provided
 */
export const renderStepContent = (
  helperFuncs,
  index,
  isDesktop,
  numSteps,
  setRunTour,
  step
) => {
  const showProgressIndicator = numSteps > 1;
  const ProgressIndicator =
    showProgressIndicator && getProgressIndicator(index, isDesktop, numSteps);

  return (
    <div data-tracking={containerTracking(containerTrackingLabel)}>
      {helperFuncs &&
        Object.keys(helperFuncs).length &&
        renderCloseButton(helperFuncs, setRunTour, step.title)}
      {!isDesktop && (
        <>
          {ProgressIndicator}
          <div className={`${baseClass}-image--mobile-wrapper`}>
            <img
              className={`${baseClass}-image--mobile`}
              src={step.mobileImages.image}
            />
          </div>
        </>
      )}
      <div className={`${baseClass}-content`}>
        <Text className={`${baseClass}-heading`} element="h2" type="display">
          {step.title}
        </Text>
        <Text className={`${baseClass}-text`}>
          {formatMessage(`step${index + 1}Message`, step.message)}
        </Text>
        {isDesktop &&
          step?.desktopImages &&
          Object.keys(step.desktopImages).length && (
            <ul className={`${baseClass}-image-list`}>
              {step.desktopImages.map((image) => (
                <li key={image.label}>
                  <figure className={`${baseClass}-image-list-figure`}>
                    <Text
                      className={`${baseClass}-image-list-label`}
                      element="figcaption"
                      type="display"
                    >
                      {image.label}
                    </Text>
                    <img
                      className={`${baseClass}-image--desktop`}
                      src={image.link}
                    />
                  </figure>
                </li>
              ))}
            </ul>
          )}
        <div className={`${baseClass}-actions`}>
          {renderActions(step.buttons, helperFuncs, isDesktop, step.title)}
        </div>
      </div>
      {isDesktop && ProgressIndicator}
    </div>
  );
};

/**
 * Generates an array of steps to be used as the React Joyride steps
 *
 * @param {object} helperFuncs object wherein each key has a function value that
 * can be used to control the ftue navigation (close, next, prev, reset)
 * @param {boolean} isDesktop result of media query hook; if true,
 * browser width is greater than desktop breakpoint
 * @param {function} setRunTour function that sets the state in parent component
 * to determine whether or not the React Joyride tour is running for ftue
 *
 * @returns {array} of step objects for ftue steps
 */
export const getFirstTimeUserExperienceSteps = (
  helperFuncs,
  isDesktop,
  setRunTour,
  steps
) => {
  const numSteps = steps.length;

  return steps.map((step, index) => ({
    content: renderStepContent(
      helperFuncs,
      index,
      isDesktop,
      numSteps,
      setRunTour,
      step
    ),
    ...stepProps,
    actionSelector: step.target.actionSelector,
    placement: isDesktop ? step.placement.desktop : step.placement.mobile,
    removeStyleClasses: step.removeFtueStylingClasses,
    spotlightClass: step.target.spotlightClass,
    spotlightPadding: step.target.spotlightPadding || 0,
    stopChecks: step.stopChecks,
    target: isDesktop ? step.target.desktop : step.target.mobile,
    styleClasses: step.ftueStylingClasses,
    stepTitle: step.title,
    tooltipClass: step.target.tooltipClass,
  }));
};
