import { CLOSED_ACCOUNT_ROLES } from './constants';
import { getCurrentAccount } from './util';

/**
 * Function used to sort accounts list by name and move default account to the top
 * @param accounts - list of accounts from user response
 * @param defaultAccountAuthGuid - current default auth guid from user response
 * @returns {[]} - new array of sorted accounts with default account at the top
 */
export const sortAccounts = (accounts, defaultAccountAuthGuid) => {
  if (!accounts?.length) {
    return [];
  }

  return accounts
    .map((x) => x)
    .sort((x, y) => {
      if (x.authGuid === defaultAccountAuthGuid) return -1;
      if (y.authGuid === defaultAccountAuthGuid) return 1;
      const nameX = x.name?.toLowerCase() ?? '';
      const nameY = y.name?.toLowerCase() ?? '';

      if (nameX < nameY) return -1;
      if (nameX > nameY) return 1;
      return 0;
    });
};

/**
 * Function used to prepare accounts list by sorting and filtering based on isClosed flag
 * @param userData - full user data object
 * @returns {{activeAccounts: [], closedAccounts: []} | {activeAccounts: []}} - object containing lists of active and closed accounts
 */
export const processAccounts = (userData) => {
  const {
    accounts: originalAccounts,
    defaultAccountAuthGuid,
    selectedAccount: { authGuid, orderId },
  } = userData;
  const sortedAccounts = sortAccounts(originalAccounts, defaultAccountAuthGuid);
  const activeAccounts = sortedAccounts.filter((x) => !x.isClosed);

  const { roles } = getCurrentAccount(sortedAccounts, authGuid || orderId);

  // The roles are Primary Manager, Billing Manager, or Service and Billing (Combo) Manager
  if (roles?.find((role) => CLOSED_ACCOUNT_ROLES[role])) {
    const closedAccounts = sortedAccounts.filter((x) => x.isClosed);

    return { activeAccounts, closedAccounts };
  }

  return { activeAccounts };
};
