export const on = (eventType, handler) => {
  document.addEventListener(eventType, handler);
};

export const off = (eventType, handler) => {
  document.removeEventListener(eventType, handler);
};

export const dispatchEvent = (eventName, eventData) => {
  document.dispatchEvent(new CustomEvent(eventName, { detail: eventData }));
};
