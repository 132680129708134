/* eslint-disable camelcase, no-undef, no-underscore-dangle */
/**
 * Allows webpack to be aware of the CDN URL for dynamic imports
 * During production build, baseUrl is read from preloaded state
 * ESlint is disabled in this file
 * Reference: https://webpack.js.org/guides/public-path/#on-the-fly
 */

__webpack_public_path__ =
  process.env.NODE_ENV === 'production'
    ? window.__PRELOADED_STATE__ &&
      window.__PRELOADED_STATE__.application &&
      window.__PRELOADED_STATE__.application.cdnBaseUrl
    : '/';
