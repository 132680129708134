export const CLOSE_BUTTON_TEST_ID = 'closeFTUE';

export const FLOATER_STYLES_MOBILE = {
  floater: {
    overflowY: 'scroll',
  },
  floaterCentered: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'none',
  },
  tooltip: {
    height: '100%',
  },
};

export const FTUE_STYLES_MOBILE = {
  buttonClose: {
    color: '#1471da',
    height: 18,
    padding: 21,
    width: 18,
  },
  tooltip: {
    height: '100%',
    width: '100%',
    padding: '40px 0 20px',
    borderRadius: 0,
  },
  tooltipContainer: {
    textAlign: 'left',
  },
  tooltipContent: {
    padding: 0,
  },
  tooltipFooter: {
    display: 'none',
  },
  options: {
    zIndex: 999999,
  },
};

export const FTUE_STYLES_DESKTOP = {
  ...FTUE_STYLES_MOBILE,
  tooltip: {
    height: 'auto',
    width: 670,
    padding: '40px 40px 20px',
    borderRadius: 10,
  },
};
