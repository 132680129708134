import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { API_CALL_TYPE, API_ERROR_MESSAGE, AUTH_TYPES } from 'utils/constants';
import { fidoV2 as fido } from '@bsd/ui-utils';

import { useMutationObserver } from 'hooks/useMutationObserver/useMutationObserver';
import { AccountManagerContext } from 'context/data/AccountManagerProvider';

import {
  setCurrentAccountBrowserStorage,
  syncCurrentAccountByAppType,
} from 'utils/applicationType';
import { processAccounts } from 'utils/closedAccounts';
import { performMegaMenuMerge } from 'utils/megaMenuUtil';
import {
  getComAuthCore,
  getScriptDataset,
  getScriptTag,
  getIsObservabilityEnabled,
} from 'utils/script';
import {
  getContentConfig,
  getCurrentAccount,
  getNavSaConfig,
  getTracerName,
  getTrackingIdHeader,
} from 'utils/util';
import { events, trackEvent } from 'utils/tracking';
import getNavSaInstance from 'utils/navServiceSa';

import initialData from 'data/data.json';
import mmData from 'data/mmData.json';
import { getConfig } from 'config';
import {
  SemanticAttributes,
  spanRequest,
  sendSpecificSpan,
} from '@bsd/observability';

export const DataContext = createContext();

export const DataContextProvider = ({ children, isMegaMenu }) => {
  const [content, setContent] = useState(isMegaMenu ? mmData : initialData);
  const [contentReady, setContentReady] = useState(false);
  const [featureFlags, setFeatureFlags] = useState({});
  const [ftueContent, setFtueContent] = useState([]);
  const [userData, setUserData] = useState(null);
  const [userDataReady, setUserDataReady] = useState(false);

  const scriptTag = getScriptTag();
  const [scriptData, setScriptData] = useState(scriptTag?.dataset);

  const initialLoginStatus = Boolean(scriptData && scriptData.authToken);
  const [isAuthenticated, setIsAuthenticated] = useState(initialLoginStatus);

  const userDataVersionRef = useRef(null);
  const userDataTimeoutRef = useRef(null);

  // TODO: Remove when testing is complete! - 10.31.22 - zherma968
  const { isApplicationTypeEnabled } = getConfig()?.featureFlags ?? false;

  const isObservabilityEnabled = getIsObservabilityEnabled();
  const hasComAuthCore = Boolean(getComAuthCore());

  const { currentAccountId, handleAccountSwitch, handleSetCurrentAccount } =
    useContext(AccountManagerContext);

  const { defaultRetryMillis, navSaRetryLimit } = getNavSaConfig();
  let refreshCounter = 0;
  // TEMPORARY functionality - watching script tag updates to enable testing in consumer app
  const onScriptMutation = useCallback(() => {
    const mutatedAttr = scriptTag?.dataset;

    if (!mutatedAttr) {
      console.error(
        `Expected a valid object containing script attributes. Instead, received ${mutatedAttr}`
      );
      return null;
    }

    // script dataset is mutated, so reference is not updated,
    // to let React know there is a change we need to update the reference
    setScriptData({ ...mutatedAttr });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMutationObserver(scriptTag, onScriptMutation);
  // END of TEMPORARY functionality

  useEffect(() => {
    const { contentUrl, megaMenuContentUrl } = getContentConfig();
    const options = { headers: { ...getTrackingIdHeader() } };
    const { ['tracking-id']: trackingId } = getTrackingIdHeader();

    const fetchContentData = async () => {
      try {
        const response = await fido.fetch(
          {
            url: contentUrl,
            key: 'content',
          },
          options
        );

        if (isObservabilityEnabled) {
          const attributes = {
            [SemanticAttributes?.CODE_FUNCTION]: 'fetchContentData',
            [SemanticAttributes?.CODE_FILEPATH]:
              'src/context/data/DataContextProvider.js',
            'app.response_status': response.status,
            'app.ref_url': document.referrer,
            'app.request_url': contentUrl,
            'app.tracking_id': trackingId,
          };

          const spanEvents = [
            `nav content endpoint ${contentUrl}`,
            `response status ${response.status}`,
          ];

          sendSpecificSpan({
            attributes,
            method: 'get',
            spanEvents,
            tracerName: getTracerName('navContentRequest'),
            urlReference: window.location.href,
            url: contentUrl,
          });
        }

        if (response.status === 200 && response.body) {
          setContent(response.body.components.globalNavigation);
          setFeatureFlags(response.body.featureFlags || {});
          setFtueContent(response.body.ftueExperience?.preInstallFtue || []);
        } else {
          trackEvent(
            events.NAV_CONTENT_ERROR(
              API_ERROR_MESSAGE(API_CALL_TYPE.navContent, response.status)
            )
          );
        }
        setContentReady(true);
      } catch (error) {
        trackEvent(events.NAV_CONTENT_ERROR(error.message));
        console.error(error);
      }
    };
    const fetchMegaMenuContentData = async () => {
      try {
        const navRequestFn = () =>
          fido.fetch(
            {
              url: contentUrl,
              key: 'content',
            },
            options
          );
        const megaMenuRequestFn = () =>
          fido.fetch(
            {
              url: megaMenuContentUrl,
              key: 'content',
            },
            options
          );

        const [navResponse, megaMenuResponse] = await Promise.all([
          navRequestFn(),
          megaMenuRequestFn(),
        ]);

        if (isObservabilityEnabled) {
          const commonAttributes = {
            [SemanticAttributes?.CODE_FUNCTION]: 'fetchMegaMenuContentData',
            [SemanticAttributes?.CODE_FILEPATH]:
              'src/context/data/DataContextProvider.js',
            'app.ref_url': document.referrer,
            'app.tracking_id': trackingId,
          };

          const navSpanEvents = [
            `nav content endpoint ${contentUrl}`,
            `response status ${navResponse.status}`,
          ];
          const megaNavSpanEvents = [
            `mega menu content endpoint ${megaMenuContentUrl}`,
            `response status ${megaMenuResponse.status}`,
          ];

          sendSpecificSpan({
            attributes: {
              ...commonAttributes,
              'app.response_status': navResponse.status,
              'app.request_url': contentUrl,
            },
            method: 'get',
            spanEvents: navSpanEvents,
            tracerName: getTracerName('navContentRequest'),
            urlReference: window.location.href,
            url: contentUrl,
          });

          sendSpecificSpan({
            attributes: {
              ...commonAttributes,
              'app.response_status': megaMenuResponse.status,
              'app.request_url': megaMenuContentUrl,
            },
            method: 'get',
            spanEvents: megaNavSpanEvents,
            tracerName: getTracerName('megaMenuContentRequest'),
            urlReference: window.location.href,
            url: megaMenuContentUrl,
          });
        }

        if (navResponse?.status !== 200) {
          trackEvent(
            events.NAV_CONTENT_ERROR(
              API_ERROR_MESSAGE(API_CALL_TYPE.navContent, navResponse.status)
            )
          );
        }
        if (megaMenuResponse?.status !== 200) {
          trackEvent(
            events.NAV_CONTENT_ERROR(
              API_ERROR_MESSAGE(
                API_CALL_TYPE.megaMenuContent,
                megaMenuResponse.status
              )
            )
          );
        }

        const continueMerge =
          navResponse?.status === 200 &&
          navResponse?.body?.components &&
          megaMenuResponse?.status === 200 &&
          megaMenuResponse?.body?.components;

        if (!continueMerge) {
          console.error(
            'Invalid content response, using default content instead!'
          );
          setContentReady(true);
          return;
        }

        const finalContent = performMegaMenuMerge(
          navResponse.body,
          megaMenuResponse.body
        );

        setContent(finalContent.components.globalNavigation);
        setFeatureFlags(finalContent.featureFlags || {});
        setFtueContent(finalContent.ftueExperience?.preInstallFtue || []);

        setContentReady(true);
      } catch (error) {
        trackEvent(events.NAV_CONTENT_ERROR(error.message));
        console.error(
          `Error processing content! Using default content instead! Error message: ${error.message}`
        );
        setContentReady(true);
      }
    };

    isMegaMenu ? fetchMegaMenuContentData() : fetchContentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!scriptData) {
      console.error(
        `Unable to fetch user data. Invalid script attributes with value ${scriptData}`
      );
      return;
    }

    const { authType, authToken } = getScriptDataset();

    if (!authToken || !AUTH_TYPES.includes(authType)) {
      return;
    }

    const fetchUserData = async () => {
      const navSA = getNavSaInstance();
      const { navigationSaUrl } = getNavSaConfig();

      const { ['tracking-id']: trackingId } = getTrackingIdHeader();
      const options = {
        headers: {
          authorization: authToken,
          ...(hasComAuthCore && { 'com-auth-core': getComAuthCore() }),
          ...getTrackingIdHeader(),
        },
        withCredentials: hasComAuthCore ? false : true,
      };

      try {
        const userDataResponse = await (isObservabilityEnabled
          ? spanRequest({
              method: 'get',
              tracerName: getTracerName('navigationRequest'),
              requestFn: () => navSA.navigation(trackingId, options),
              attributes: {
                [SemanticAttributes.CODE_FUNCTION]: 'fetchUserData',
                [SemanticAttributes.CODE_FILEPATH]:
                  'src/context/data/DataContextProvider.js',
                ref_url: document.referrer,
                'app.tracking_id': trackingId,
              },
              url: `${navigationSaUrl}navigation`,
            })
          : navSA.navigation(trackingId, options));

        let dataReadyToBeSet = false;
        let needUserDataRefresh = false;

        if (userDataResponse?.data && userDataResponse.httpStatus === 200) {
          const { version, key, refreshAfterMillis } = userDataResponse.data;

          needUserDataRefresh = Boolean(refreshAfterMillis);
          // set user data when the version changes (means more/updated data is coming) or when we are not going to make any more calls
          dataReadyToBeSet =
            (version && userDataVersionRef.current !== version) ||
            !needUserDataRefresh;

          if (dataReadyToBeSet) {
            setUserData((userData) => {
              if (!userDataResponse.data?.selectedAccount)
                return {
                  ...userData,
                  ...userDataResponse.data,
                  activeAccounts: [],
                };

              const { activeAccounts, closedAccounts } = processAccounts(
                userDataResponse.data
              );

              return {
                ...userData,
                ...userDataResponse.data,
                activeAccounts,
                closedAccounts,
              };
            });
            setIsAuthenticated(!!key?.length);
            userDataVersionRef.current = userDataResponse.data.version;
          }
        } else {
          trackEvent(
            events.USER_DATA_ERROR(
              API_ERROR_MESSAGE(
                API_CALL_TYPE.userData,
                userDataResponse.httpStatus
              )
            )
          );
          console.error(
            `Unable to fetch user data. Unsuccessful Navigation SA call with status ${userDataResponse.httpStatus}`
          );

          // If nav fails due to a 401 explicitly set authenticated state to false and do not retry
          if (userDataResponse.httpStatus === 401) {
            setIsAuthenticated(false);
            return;
          }
          needUserDataRefresh = true;
        }

        // If nav call fails due to some other error, attempt the call again after separate retry limit
        // Needed to ensure nav is not put into an unauth state when valid data already exists or a previous call timed out
        if (needUserDataRefresh && refreshCounter <= navSaRetryLimit) {
          userDataTimeoutRef.current = setTimeout(
            fetchUserData,
            defaultRetryMillis
          );
        }

        if (dataReadyToBeSet) {
          setUserDataReady(true);
        }
        refreshCounter++;
      } catch (error) {
        trackEvent(events.USER_DATA_ERROR(error.message));

        console.error(error);
      }
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptData?.authToken]);

  useEffect(() => {
    return () => {
      if (userDataTimeoutRef.current) {
        clearTimeout(userDataTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!userData || !userData.accounts?.length) {
      return null;
    }

    const defaultAccount = userData.accounts.find(
      (x) => x.authGuid === userData.defaultAccountAuthGuid
    );
    if (defaultAccount) {
      // TEMPORARY FUNCTIONALITY
      // TODO: remove flag and set to true when testing is complete - zherma968 - 10.28.22
      defaultAccount.isDefault = featureFlags.IsDefaultAccountEnabled;
      // END OF TEMPORARY FUNCTIONALITY
    }

    handleSetCurrentAccount(userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags, userData]);

  useEffect(() => {
    if (!currentAccountId || !userDataReady) {
      return null;
    }

    if (isApplicationTypeEnabled) {
      setCurrentAccountBrowserStorage(
        getCurrentAccount(userData.accounts, currentAccountId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountId]);

  useEffect(() => {
    if (!userDataReady || !userData.accounts?.length) {
      return null;
    }

    if (isApplicationTypeEnabled) {
      syncCurrentAccountByAppType(
        currentAccountId,
        userData,
        handleAccountSwitch
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataReady]);

  return (
    <DataContext.Provider
      value={{
        contentReady,
        userDataReady,
        ftue: ftueContent,
        footer: content.navigation.footer,
        header: isMegaMenu
          ? content.navigation.megamenu
          : content.navigation.header,
        featureFlags,
        isAuthenticated,
        isMegaMenu,
        scriptData,
        userData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);

DataContextProvider.propTypes = {
  children: PropTypes.node,
  isMegaMenu: PropTypes.bool,
};
