// Constants
import { EVENT_METHODS, EVENT_NAME } from 'utils/constants';

// External Utilities
import { dispatchCustomEvent } from '@bsd/ui-utils/tracking';

export const globalNavTransactionError = (errorMessage, errorAction) => {
  return {
    eventAction: errorAction,
    eventMethod: EVENT_METHODS.sendError,
    eventName: EVENT_NAME.globalNavTransactionError,
    eventPage: window.digitalData?.page?.pageInfo?.pageName,
    attributes: {
      errorMessage: errorMessage,
    },
  };
};

export const events = {
  GLOBAL_NAV_RENDER_ERROR: (errorMessage) =>
    globalNavTransactionError(errorMessage, 'error rendering Global Nav'),
  NAV_CONTENT_ERROR: (errorMessage) =>
    globalNavTransactionError(errorMessage, 'error global nav content'),
  USER_DATA_ERROR: (errorMessage) =>
    globalNavTransactionError(errorMessage, 'error global nav user data'),
  ACCOUNT_SWITCH_ERROR: (errorMessage) =>
    globalNavTransactionError(errorMessage, 'error global nav account switch'),
  NAV_ENABLEMENT_ERROR: (errorMessage) =>
    globalNavTransactionError(errorMessage, 'error mega menu enablement'),
};

export const trackEvent = (event) => {
  dispatchCustomEvent({ eventInfo: event }, false);
};
