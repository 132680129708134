import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import PropTypes from 'prop-types';

import {
  getFirstTimeUserExperienceSteps,
  handleFtueCallback,
} from 'utils/ftueUtils';
import {
  FLOATER_STYLES_MOBILE,
  FTUE_STYLES_DESKTOP,
  FTUE_STYLES_MOBILE,
} from './constants';

import { DESKTOP_QUERY } from 'utils/constants';
import { useMediaQuery } from 'bsd-global-nav-design-ui';

export default function FirstTimeUserExperience({
  runTour,
  setRunTour,
  steps,
  profileData,
  processingMode,
}) {
  const [activeSelector, setActiveSelector] = useState('');
  const [helperFuncs, setHelperFuncs] = useState({});

  useEffect(() => {
    // reset activeSelector at start of tour
    if (runTour) {
      setActiveSelector('');
    }
  }, [runTour, setActiveSelector]);

  const handleCallback = (tour) =>
    handleFtueCallback(
      activeSelector,
      helperFuncs,
      isDesktop,
      setActiveSelector,
      setRunTour,
      tour,
      profileData,
      processingMode
    );
  const handleGetHelpers = (helpers) => setHelperFuncs(helpers);

  const isDesktop = useMediaQuery(DESKTOP_QUERY);

  const lastBtn = document.querySelector('[title="Last"]');
  const nextBtn = document.querySelector('[title="Next"]');

  if (lastBtn) {
    lastBtn.parentNode.remove();
  }
  if (nextBtn) {
    nextBtn.parentNode.remove();
  }

  return (
    <Joyride
      callback={handleCallback}
      continuous
      disableOverlayClose
      floaterProps={{
        disableAnimation: true,
        hideArrow: isDesktop ? false : true,
        styles: !isDesktop && {
          ...FLOATER_STYLES_MOBILE,
        },
      }}
      getHelpers={handleGetHelpers}
      hideBackButton
      run={runTour}
      scrollToFirstStep={isDesktop ? true : false}
      steps={getFirstTimeUserExperienceSteps(
        helperFuncs,
        isDesktop,
        setRunTour,
        steps
      )}
      styles={
        isDesktop
          ? {
              ...FTUE_STYLES_DESKTOP,
            }
          : { ...FTUE_STYLES_MOBILE }
      }
    />
  );
}

FirstTimeUserExperience.propTypes = {
  processingMode: PropTypes.string.isRequired,
  profileData: PropTypes.object,
  runTour: PropTypes.bool.isRequired,
  setRunTour: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
};
